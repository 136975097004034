$mobileBreakpoint: 1024px;

:root {
    --swiper-theme-color: black;
    --swiper-pagination-bullet-inactive-color: white;
    --swiper-pagination-bullet-inactive-opacity: 1;
    --swiper-pagination-bullet-opacity: .2;
    --swiper-pagination-bottom: 0;
}

.nn-trending-items {
    .swiperContainer {
        margin: 0 auto;
    }

    .swiperWrap {
        height: 100%;
        width: 100%;
        position: relative;
        display: block;
        text-align: left;

        .swiper-pagination {
            position: relative;
            top: 3px;
            margin-bottom: 10px;

            .swiper-pagination-bullet{
                border: 1px solid #5e5e5e;
            }
        }
    }

    .nnSwiper {
        max-width: calc(100% - 40px);
        margin: 0 20px;
    }

    .swiper-button-next, .swiper-button-prev {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 100%;
        margin-top: 0;

        &::after {
            position: absolute;
            top: 50%;
            height: 20px;
            transform: translateY(-50%);
            max-width: 100%;
            max-height: 100%;
            font-size: 14px;
            margin-top: 0;
        }
    }

    .swiper-button-next {
        left: auto;
        right: 0;
    }

    .productLink {
        display: flex;
        flex-direction: column;
        align-content: center;
        max-width: 345px;
        font-family: 'Montserrat', sans-serif;
        text-align: center;
        cursor: pointer;
        margin: 0 auto;
        margin-bottom: 20px;

        .productImage {
            width: 100%;
            max-width: 345px;
        }

        .productDetails {
            width: 65%;
            margin: 7px auto 2px;

            @media screen and (min-width: 1024px) {
                width: 80%;
            }

            .productName {
                font-size: 12px;
                hyphens: none;
                text-transform: uppercase;
                margin-bottom: 5px;
            }

            .productPrice {
                margin: 0;
                font-size: 13px;
            }
        }
    }
}
